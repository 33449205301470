import * as React from "react"
import PropTypes, { node } from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

const Footer = ({ site }) => {
  const query = useStaticQuery(graphql`
    query Footers {
      menu: allStrapiMenuFooter {
        edges {
          node {
            menu_item_footer {
              Titulo
              Perfiles
              sub_item_footer {
                Titulo
                Link_externo
                pagina {
                  title
                  slug
                  site
                }
              }
            }
          }
        }
      }

      footer: allStrapiConfiguracionGlobalFooter {
        edges {
          node {
            Titulo_atencion_nacional
            Titulo_ciudad_dos
            Titulo_ciudad_uno
            Titulo_linea_atencion
            Titulo_resto_del_pais
            Direccion
            Direccion_dos
            Numero_atencion_nacional
            Telefono
            Telefono_dos
          }
        }
      }

      redes: allStrapiRedesSociales {
        edges {
          node {
            Titulo
            Link
            icono {
              url
            }
          }
        }
      }
    }
  `)

  if (typeof window !== "undefined") {
    if (site) window.localStorage.setItem("currentSite", site)
    else site = window.localStorage.getItem("currentSite")
  }

  const configuracionGlobal = query.footer.edges[0].node
  const menu = query.menu.edges
  const menu_item_footer = menu[0].node.menu_item_footer
  const redes = query.redes.edges
  let url_global = process.env.GATSBY_API_URL

  return (
    <>
      <footer
        className="mitexto mx-auto bg-footer pt-8 border-t border-gray-400"
        style={{ position: "relative" }}
      >
        <div
          className="alineacion_icono_footer w-16 h-16 text-center"
          style={{ position: "absolute", top: "-30px", right: "4em" }}
          tabIndex="1"
        >
          <a
            href="#"
            tabIndex="1"
            className="text-center w-full h-full cursor-pointer"
          >
            <img
              src={
                process.env.GATSBY_API_URL + "/uploads/bt_top_d3fc75f44c.png"
              }
              className="mx-auto my-4"
              alt="Boton ir arriba"
              title="Boton ir arriba"
              width="70"
              height={75}
            />
          </a>
        </div>
        <div className="flex px-10 py-8 max-w-full">
          <div className="mitexto_color alineacion_contenido_footer w-full mx-auto flex flex-wrap">
            <Item item={menu_item_footer} site={site} key={0} />

            <div className="flex w-full ubicacion_contenido_telefonico lg:w-1/3 lg:justify-end lg:text-left">
              <div className="px-3 md:px-0" tabIndex="1">
                {/* <img
                  className="icono_derecho_footer"
                  style={{ position: "relative" }}
                  src={
                    process.env.GATSBY_API_URL +
                    "/uploads/telefono2_178111ef19.png"
                  }
                  alt="Linea de atención"
                  title="Linea de atención"
                  width={50}
                  height={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                /> */}
                <ul className="tamano_numero_telefonico list-reset items-center pt-3">
                  <li tabIndex="1">
                    <p
                      className="mitexto_color titulo_menu_footer font-bold text-green-light"
                      // style={{ fontSize: "19px" }}
                    >
                      {configuracionGlobal.Titulo_linea_atencion}
                    </p>
                  </li>
                  <br></br>
                  <li tabIndex="1">
                    <p
                      className="tamano_numero_telefono text-poppins-bold text-white"
                      style={{
                        fontWeight: 800,
                        fontSize: "17px",
                        lineHeight: "30px",
                      }}
                    >
                      {configuracionGlobal.Titulo_ciudad_uno}
                    </p>
                  </li>
                  <li tabIndex="1">
                    <p
                      className="tamano_numero_telefono text-poppins-regular text-white"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Télefono: {configuracionGlobal.Telefono}
                      <br></br>
                      Dirección: {configuracionGlobal.Direccion}
                    </p>
                  </li>
                  <br></br>
                  {/* SEGUNDA CIUDAD, DIRECCION, TELEFONO  */}
                  <li tabIndex="1">
                    <p
                      className="tamano_numero_telefono text-poppins-bold text-white"
                      style={{
                        fontWeight: 800,
                        fontSize: "17px",
                        lineHeight: "30px",
                      }}
                    >
                      {configuracionGlobal.Titulo_ciudad_dos}
                    </p>
                  </li>
                  <li tabIndex="1">
                    <p
                      className="tamano_numero_telefono text-poppins-regular text-white"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Télefono: {configuracionGlobal.Telefono_dos}
                      <br></br>
                      Dirección: {configuracionGlobal.Direccion_dos}
                    </p>
                  </li>
                  {/* FINAL DE SEGUNDA CIUDAD, DIRECCION, TELEFONO */}
                  <br></br>
                  {/* RESTO DEL PAIS */}
                  <li tabIndex="1">
                    <p
                      className="tamano_numero_telefono text-poppins-bold text-white"
                      style={{
                        fontWeight: 800,
                        fontSize: "17px",
                        lineHeight: "30px",
                      }}
                    >
                      {configuracionGlobal.Titulo_resto_del_pais}
                    </p>
                  </li>
                  <li tabIndex="1">
                    <p
                      className="tamano_numero_telefono text-poppins-regular text-white"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {configuracionGlobal.Titulo_atencion_nacional}{" "}
                      {configuracionGlobal.Numero_atencion_nacional}
                    </p>
                  </li>
                  {/* FINAL DE RESTO DEL PAIS */}

                  <br></br>
                  <h4
                    className="sub_normatividad_footer margenes_siguenos_footer text-poppins-bold text-white relative left-0"
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      marginLeft: "1rem",
                      zIndex: "99",
                    }}
                  >
                    Síguenos:
                    {redes.map(({ node: redes_sociales }, i) => {
                      return (
                        <a
                          tabIndex="1"
                          href={redes_sociales.Link}
                          target="_blank"
                          className="relative"
                          style={{ marginLeft: "1rem" }}
                          key={`n-${i}`}
                        >
                          <img
                            className="tamano_iconos_redes_sociales"
                            src={url_global + redes_sociales.icono[0].url}
                            alt={redes_sociales.Titulo}
                            title={redes_sociales.Titulo}
                            width={25}
                            height={25}
                          />
                        </a>
                      )
                    })}
                  </h4>
                  <li
                    tabIndex="1"
                    className="ubicar_icono_eps_delagente_footer"
                    style={{ textAlign: "-webkit-right" }}
                  >
                    <img
                      style={{ width: "11rem" }}
                      src={
                        process.env.GATSBY_API_URL +
                        "/uploads/logo_pie_eps_de_la_gente_7b3090ee78.png"
                      }
                      className="tamano_icono_eps_delagente mt-11"
                      alt="Logo EPSdelagente"
                      title="Logo EPSdelagente"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      width={270}
                      height={47}
                    />
                  </li>
                </ul>
              </div>
              {/*----------INICIO ICONO VERTICAL ------------*/}
              <div
                className="flex w-full ubicacion_contenido_super_subsidio lg:justify-end text-right"
                style={{ width: "15%" }}
              >
                <a href="https://www.ssf.gov.co/" target="_blank">
                  <img
                    style={{ width: "2rem", height: "15rem" }}
                    src={
                      process.env.GATSBY_API_URL +
                      "/uploads/Supersubsidio_1_girar_190_7dedbfe4ac.png"
                    }
                    className="mt-11 super_subsidio_imagen"
                    alt="Logo EPSdelagente"
                    title="Logo EPSdelagente"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    width={270}
                    height={47}
                  />
                </a>
              </div>
              {/*----------FINAL ICONO VERTICAL -------------*/}
            </div>
          </div>
        </div>

        <div className="flex flex-col mitexto_color mitexto margen_vigilado_super_salud_footer lg:grid grid-cols-2 bg-white py-3 gap-4">
          <div
            tabIndex="1"
            className="mitexto alinear_vigilado_super_salud_footer"
          >
            <a
              href="https://www.supersalud.gov.co/es-co/Paginas/Home.aspx"
              target="_blank"
            >
              <img
                className="relative icono_inferior_footer"
                alt="Logo vigilado super salud"
                title="Logo vigilado super salud"
                style={{ left: "5rem", width: "10rem", height: "5rem" }}
                src={
                  process.env.GATSBY_API_URL +
                  "/uploads/Supersalud_1_db638ca399.png"
                }
                formats={["auto", "webp", "avif"]}
              />
            </a>
          </div>

          <div className="mitexto">
            {" "}
            <p
              className="relative mitexto mitexto_color text-poppins-regular color-texto-parrafo texto_inferior_footer"
              style={{ paddingLeft: "11rem", top: "1rem" }}
            >
              Todos los derechos reservados - Desarrollado por{" "}
              <Link tabIndex="1" to="/">
                {" "}
                <b>Prestigio Digital</b>
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

function Item(payload) {
  if (!payload || !payload.length <= 0) return <></>

  const listItems = payload.item.map((node, i) => {
    return (
      <div
        className="ubicar_contenido_menu_footer flex w-full lg:w-1/3"
        style={{ position: "relative", left: "13rem" }}
        key={i}
      >
        <div className="px-3 margen_menu_footer margen_normativa_footer md:px-0">
          <h3 className="mitexto_color titulo_menu_footer font-bold text-green-light">
            {node.Titulo}
          </h3>
          <ul
            style={{ width: "70%" }}
            className="mitexto_color sub_menu_footer list-reset items-center pt-3 text-white text-base font-thin"
          >
            <SubItem node={node} />

            {node.Perfiles === "Si" && (
              <li className="mitexto_color">
                <div
                  className="show_perfilador_footer"
                  style={{
                    display: "none",
                    zIndex: "78",
                    position: "relative",
                    marginBottom: "2rem",
                    right: "1rem",
                  }}
                >
                  <div className="dropdown inline-block relative">
                    <button
                      id="opcion2"
                      className="rounded-full text-green-300 bg-gray-500 font-semibold py-1 px-4 inline-flex items-center"
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#c5cc0c",
                      }}
                    >
                      <span
                        id="sitios"
                        className="mr-1"
                        style={{ fontSize: "11px" }}
                      >
                        {payload.site}
                      </span>

                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        style={{ color: "white !important" }}
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>{" "}
                      </svg>
                    </button>

                    <ul
                      id="menu_hide2"
                      style={{ position: "relative" }}
                      className="absolute hidden text-gray-700 pt-1"
                    >
                      <li className="">
                        <a
                          tabIndex="1"
                          className="rounded-t text-poppins-regular bg-gray-50 hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap"
                          href="/personas"
                        >
                          <p id="personas">Personas</p>
                        </a>
                      </li>
                      <li className="">
                        <a
                          tabIndex="1"
                          id="Empresas"
                          className="bg-gray-50 text-poppins-regular hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap"
                          href="/empresas"
                        >
                          <p id="empresas">Empresas</p>
                        </a>
                      </li>
                      <li className="">
                        <a
                          tabIndex="1"
                          id="Proveedores"
                          className="rounded-b text-poppins-regular bg-gray-50 hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap"
                          href="/proveedores"
                        >
                          <p id="proveedores">Proveedores</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            )}
          </ul>

          {node.Perfiles === "Si" && (
            <nav className="hide_perfil_footer my-8">
              <ul className="perfilador_footer md:flex items-center justify-between text-base text-white pt-4 md:pt-0 ">
                <li>
                  <a
                    tabIndex="1"
                    className="mitexto_color text-poppins-regular inline-block no-underline rounded-full bg-green-hover py-1 px-2 font-semibold"
                    href={"/personas"}
                  >
                    Personas
                  </a>
                </li>
                <li>
                  <a
                    tabIndex="1"
                    className="mitexto_color text-poppins-regular inline-block no-underline rounded-full hover:text-green-600 hover:bg-white py-1 px-2"
                    href={"/empresas"}
                  >
                    Empresas
                  </a>
                </li>
                <li>
                  <a
                    tabIndex="1"
                    className="mitexto_color text-poppins-regular inline-block no-underline rounded-full hover:text-green-600 hover:bg-white py-1 px-2"
                    href={"/proveedores"}
                  >
                    Proveedores
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    )
  })

  return listItems
}

function SubItem(payload) {
  if (!payload.node || !payload.node.sub_item_footer) return <></>

  const listItems = payload.node?.sub_item_footer.map((submenu, i) => (
    <li key={`s-${i}`}>
      {submenu?.pagina?.site === null && (
        <a
          tabIndex="1"
          className="mitexto_color text-poppins-regular inline-block no-underline hover:text-black py-1"
          href={`${
            submenu.Link_externo
              ? submenu.Link_externo
              : "/" + submenu?.pagina?.slug
          }`}
        >
          {submenu?.Titulo}
        </a>
      )}

      {submenu?.pagina?.site !== null && (
        <a
          tabIndex="1"
          className="mitexto_color text-poppins-regular inline-block no-underline hover:text-black py-1"
          href={`${
            submenu.Link_externo
              ? submenu.Link_externo
              : "/" + submenu?.pagina?.site + "/" + submenu?.pagina?.slug
          }`}
        >
          {submenu?.Titulo}
        </a>
      )}
    </li>
  ))

  return listItems
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
