import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { CookieNotice } from "gatsby-cookie-notice"
// gatsby-plugin-gdpr-cookies
// import { CookieConsent } from "gatsby-plugin-gdpr-cookies"

// import { OutboundLink } from "gatsby-plugin-google-gtag"
// import axios from "axios"

// import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

// initializeAndTrack()

const Header = ({ site, page }) => {
  const query = useStaticQuery(graphql`
    query Headers {
      servicio: allStrapiBotonServiciosEnLinea {
        edges {
          node {
            Titulo
            Enlace
            Icono {
              url
            }
            Activo
          }
        }
      }

      supersalud: allStrapiSuperSaluds(sort: { order: ASC, fields: id }) {
        edges {
          node {
            Titulo
            Link
            Activo
          }
        }
      }

      allStrapiMainMenu {
        edges {
          node {
            menu_item {
              title
              site
              page {
                slug
              }
              sub_item {
                title
                page {
                  slug
                  title
                  site
                }
              }
            }
          }
        }
      }
    }
  `)
  const menu = query.allStrapiMainMenu.edges
  const servicios = query.servicio.edges
  let url_global = process.env.GATSBY_API_URL
  let url_comfenalco = "https://epsdelagente.com.co/"

  // axios
  //   .post(
  //     "http://localhost:1337/api/auth/local/",
  //     // { event_type: "webhook" },
  //     {
  //       timeout: 2000000,
  //       maxBodyLength: Infinity,
  //       headers: {
  //         "Content-Type": "application/json",
  //         // Authorization: "token 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",
  //         // Accept: "*/*",
  //       },
  //       body: JSON.stringify({
  //         identifier: "andres.sabogal@megadev.com.co",
  //         password: "bfu)bK25LsaLjm?",
  //       }),
  //     }
  //   )
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log("TOKEN", data.jwt) // Aquí obtienes el token
  //   })
  //   .catch(error => {
  //     console.error("Error:", error)
  //   })

  // fetch("http://localhost:1337/api/auth/local", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     identifier: "andres.sabogal@megadev.com.co",
  //     password: "bfu)bK25LsaLjm?",
  //   }),
  // })
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log("TOKEN", data.jwt) // Aquí obtienes el token
  //   })
  //   .catch(error => {
  //     console.error("Error:", error)
  //   })

  return (
    <>
      <div className="relative top-8 menu_mode_black" tabIndex="1">
        <header className="container mx-auto">
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-K6GJ6JT"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>

          {page !== "politica-cookies-epsdelagente" && (
            <div id="fondo-aviso-cookies" className="fondo-aviso-cookies"></div>
          )}

          <nav className="w-full z-30 flex items-center justify-between flex-wrap p-6 ajustar_tamano_text_inicio mitexto_color">
            <div className="buscar buscador_lg" style={{ display: "none" }}>
              <form id="demo-b" className="buscador">
                <input type="search" id="buscadores" />
              </form>
            </div>

            <div className="relative w-2/5 mr-16 iniciar_sesion_nav_header lg:hidden flex items-center flex-shrink-0 text-white mr-6">
              {servicios.map(({ node: servicio_linea }, i) => {
                if (servicio_linea.Activo === true) {
                  return (
                    <a href={servicio_linea.Enlace} key={`m-${i}`}>
                      <button className="w-full background-secundary bg-green-900 text-white h-12  font-bold py-2 px-4 rounded-full inline-flex items-center texto-alineado">
                        {/* <div className="leading-4 text-center flex items-center mx-auto ml-auto mr-auto"> */}
                        <div className="leading-4 text-center flex items-center mx-auto">
                          <img
                            // className="relative right-4 fill-current w-11 h-11 mr-2"
                            className="relative right-4 fill-current w-11 h-11"
                            src={url_global + servicio_linea.Icono.url}
                            alt={servicio_linea.Titulo}
                            title={servicio_linea.Titulo}
                          />
                          <span className="relative right-2 text-xs text-poppins-regular">
                            {servicio_linea.Titulo}
                          </span>
                        </div>
                      </button>
                    </a>
                  )
                }
              })}
            </div>

            <div
              className="ajustar_tamano_text_inicio w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
              id="nav-content"
              tabIndex="1"
              style={{ zIndex: "99999999" }}
            >
              <ul className="list-reset lg:flex justify-end flex-1 items-center">
                {/* ESTE ESPACIO ES PARA LOS BOTONES RESTANTES  */}
                <li className="mr-3">
                  <div className="relative inline-block text-left">
                    <a
                      target="_blank"
                      href="https://www.comfenalcovalle.com.co/personas/nosotros/transparencia-y-acceso-a-la-informacion-publica/"
                    >
                      <div>
                        <button
                          id="menu-button"
                          className="gap-2 color-texto-parrafo text-poppins-regular inline-flex w-full justify-center rounded-md px-4 py-2"
                          aria-expanded="true"
                          aria-haspopup="true"
                        >
                          Transparencia
                        </button>
                      </div>
                    </a>
                  </div>
                </li>

                {/* ESTE ESPACIO ES PARA  BOTONES RESTANTES*/}
                <li className="mr-3">
                  <a
                    tabIndex="1"
                    className={
                      "text-poppins-regular perfil_mode_black inline-block no-underline rounded-full py-2 px-4 " +
                      (site === "personas"
                        ? "text-white bg-green-hover font-semibold"
                        : "color-texto-parrafo hover:text-green-700 hover:bg-green-hover")
                    }
                    href={"/personas"}
                  >
                    Personas
                  </a>
                </li>
                <li className="mr-3">
                  <a
                    tabIndex="1"
                    className={
                      "text-poppins-regular perfil_mode_black inline-block no-underline rounded-full py-2 px-4 " +
                      (site === "empresas"
                        ? "text-white fondo_rol_empresas font-semibold"
                        : "color-texto-parrafo hover:text-green-900 hover:bg-green-hover")
                    }
                    href={"/empresas"}
                  >
                    Empresas
                  </a>
                </li>
                <li className="mr-3">
                  <a
                    tabIndex="1"
                    className={
                      "text-poppins-regular perfil_mode_black inline-block no-underline rounded-full py-2 px-4 " +
                      (site === "proveedores"
                        ? "text-white text-poppins-bold fondo_rol_proveedor font-semibold"
                        : "color-texto-parrafo hover:text-green-700 hover:bg-green-hover")
                    }
                    href={"/proveedores"}
                  >
                    Proveedores
                  </a>
                </li>
                <li className="mr-3">
                  <div className="buscar">
                    <form id="demo-b" className="buscador">
                      <input tabIndex="1" type="search" id="buscador" />
                    </form>
                  </div>
                </li>

                {servicios.map(({ node: servicio_linea }, i) => {
                  if (servicio_linea.Activo === true) {
                    return (
                      <li
                        style={{ top: "0.3rem", width: "8rem" }}
                        className="relative mr-3 ancho_boton_servicio_linea"
                        key={`ser-${i}`}
                      >
                        <a tabIndex="1" href={servicio_linea.Enlace}>
                          <button className="w-full background-secundary bg-green-900 text-white h-12 font-bold py-2 px-4 rounded-full inline-flex items-center altura_boton_sesion">
                            {/* <div className="leading-4 text-center flex items-center ml-auto mr-auto"> */}
                            <div className="leading-4 text-center flex items-center mx-auto">
                              <img
                                // className="relative right-4 fill-current w-11 h-11 mr-2 tamano_icono_servicio"
                                className="relative right-4 fill-current w-11 h-11 tamano_icono_servicio"
                                src={url_global + servicio_linea.Icono.url}
                                alt={servicio_linea.Titulo}
                                title={servicio_linea.Titulo}
                              />
                              <span className="relative right-2 linear_logo_servicio_linea text-poppins-regular">
                                {servicio_linea.Titulo}
                              </span>
                            </div>
                          </button>
                        </a>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </nav>
          <nav
            style={{ marginTop: "0.5rem", zIndex: "9" }}
            className="relative alinear_menu_dos text-poppins-bold-dos  lg:relative -top-5 z-30 flex items-center justify-end flex-wrap"
          >
            <div
              style={{ marginTop: "-6.7rem" }}
              className="relative boton_desplegable block lg:hidden pr-4"
            >
              <button
                id="nav-toggles"
                className="rounded-full h-11 w-11 justify-center border rounded flex items-center p-1 bg-green-900 text-white hover:text-white-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
              >
                <svg
                  className="fill-current h-6 w-6"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>

              {/*---------------------------------- Etiquetas para movil menu desplegable  -------------------------------------- */}

              <ul
                id="menu_show"
                className="absolute hidden text-gray-700 pt-1"
                style={{
                  right: "0rem",
                  textAlign: "right",
                  width: "13rem",
                }}
              >
                <li className="">
                  {site === "proveedores" && (
                    <div className="mitexto header_icono_home_desplegable">
                      <a
                        className="text-poppins-regular flex items-center font-bold"
                        href={"/" + site}
                      >
                        <img
                          src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                          className="alineacion_imagen_home_desplegable"
                          alt="Logo EPSdelagente"
                          title="Logo EPSdelagente"
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          style={{ width: "400px", zIndex: "99999" }}
                        />
                      </a>
                    </div>
                  )}
                </li>
                <li className="">
                  {site === "empresas" && (
                    <div className="mitexto header_icono_home_desplegable">
                      <a
                        className="text-poppins-regular flex items-center font-bold"
                        href={"/" + site}
                      >
                        <img
                          src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                          className="alineacion_imagen_home_desplegable"
                          alt="Logo EPSdelagente"
                          title="Logo EPSdelagente"
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          style={{ width: "400px", zIndex: "99999" }}
                        />
                      </a>
                    </div>
                  )}
                </li>
                <li className="">
                  {site === "personas" && (
                    <div className="mitexto header_icono_home_desplegable">
                      <a
                        className="text-poppins-regular flex items-center font-bold"
                        href={"/" + site}
                      >
                        <img
                          src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                          className="alineacion_imagen_home_desplegable"
                          alt="Logo EPSdelagente"
                          title="Logo EPSdelagente"
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          style={{ width: "400px", zIndex: "99999" }}
                        />
                      </a>
                    </div>
                  )}
                </li>

                <div className="block mitexto nav_header_perfilador lg:hidden pr-4">
                  <div
                    className=""
                    style={{
                      zIndex: "78",
                      position: "relative",
                      paddingLeft: "0rem",
                    }}
                  >
                    <div className="dropdown inline-block relative">
                      <button
                        id="opcion"
                        className="rounded-full text-green-300 bg-gray-500 font-semibold py-3 px-4 inline-flex items-center"
                        style={{
                          color: "#c5cc0c",
                          position: "relative",
                          left: "1rem",
                        }}
                      >
                        <span
                          id="sitios"
                          className="mr-1"
                          style={{ fontSize: "11px" }}
                        >
                          {site}
                        </span>

                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          style={{ color: "white !important" }}
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>{" "}
                        </svg>
                      </button>

                      <ul
                        id="menu_hide"
                        className="relative hidden font-semibold text-poppins-regular text-gray-700 pt-1"
                      >
                        <div className="grid">
                          <li className="inline-flex items-center">
                            <img
                              className="relative icono_boton_flotante_rueda"
                              src={
                                process.env.GATSBY_API_URL +
                                "/uploads/personas_gris_2ff7231d49.png"
                              }
                              formats={["auto", "webp", "avif"]}
                              width={35}
                              height={35}
                              alt="Boton aqui te ayudamos"
                              title="Boton aqui te ayudamos"
                            />

                            <a
                              className="mitexto_color rounded-t color-texto-parrafo hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap"
                              href="/personas"
                            >
                              <p id="personas">Personas</p>
                            </a>
                          </li>
                          <li className="inline-flex items-center">
                            <img
                              className="relative icono_boton_flotante_rueda"
                              src={
                                process.env.GATSBY_API_URL +
                                "/uploads/empresas_gris_3205017874.png"
                              }
                              formats={["auto", "webp", "avif"]}
                              width={35}
                              height={35}
                              title="Boton aqui te ayudamos"
                              alt="Boton aqui te ayudamos"
                            />
                            <a
                              id="Empresas"
                              className="mitexto_color color-texto-parrafo hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap"
                              href="/empresas"
                            >
                              <p id="empresas">Empresas</p>
                            </a>
                          </li>
                          <li className="inline-flex items-center">
                            <img
                              className="relative icono_boton_flotante_rueda"
                              src={
                                process.env.GATSBY_API_URL +
                                "/uploads/proveedores_gris_ff5f417eba.png"
                              }
                              formats={["auto", "webp", "avif"]}
                              width={35}
                              height={35}
                              alt="Boton aqui te ayudamos"
                              title="Boton aqui te ayudamos"
                            />
                            <a
                              id="Proveedores"
                              className="mitexto_color rounded-b color-texto-parrafo hover:bg-gray-300 py-2 px-4 block whitespace-no-wrap"
                              href="/proveedores"
                            >
                              <p id="proveedores">Proveedores</p>
                            </a>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* </li> */}

                {/*INICIO BOTONES SUPER SALUD MOVIL*/}
                <li className="">
                  <div className="relative inline-block text-left">
                    <a
                      target="_blank"
                      href="https://www.comfenalcovalle.com.co/personas/nosotros/transparencia-y-acceso-a-la-informacion-publica/"
                    >
                      <div>
                        <button
                          id="menu-button-movil"
                          className="mt-2 gap-2 mitexto mitexto_color color-texto-parrafo text-poppins-regular inline-flex w-full rounded-md pl-2 py-2"
                          aria-expanded="true"
                          aria-haspopup="true"
                        >
                          {/* <svg
                            className="-mr-1 ml-2 h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                          </svg> */}
                          Transparencia
                        </button>
                      </div>
                    </a>
                    {/* 
                    <div
                      className="relative hidden menu_super_salud right-0 z-10 w-56 origin-top-right rounded-md bg-white"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      <div
                        className="py-1 color-texto-parrafo text-poppins-regular"
                        role="none"
                      >
                        {super_salud.map(({ node: salud }, i) => {
                          if (salud.Activo === true) {
                            return (
                              <a
                                href={salud.Link}
                                target="_self"
                                className="color-texto-parrafo text-poppins-regular block px-4 py-2 text-sm"
                                role="menuitem"
                                tabIndex="-1"
                                id={`menu-item-${i}`}
                                key={`n-${i}`}
                              >
                                {salud.Titulo}
                              </a>
                            )
                          }
                        })}
                      </div>
                    </div> */}
                  </div>
                </li>

                {/*FINAL BOTONES SUPER SALUD MOVIL*/}

                <li className="">
                  <a
                    className="mitexto mitexto_color estilo_menu_principal text-poppins-regular inline-block no-underline color-texto-parrafo text-poppins-regular text-poppins-bold-dos rounded-full  hover:text-white hover:bg-green-900  hover:text-black py-2 px-4"
                    href={"/" + site}
                  >
                    Inicio
                  </a>
                </li>

                <Item_dos item={menu[0]} key={0} site={site} />
              </ul>

              {/*------------------------------------Fin de las etiquetas movil menu desplegable----------------------------------  */}
            </div>

            {/* </nav>

           <nav>  */}

            <div className="contenedor_dark_mode_header">
              <div
                className="alinear_segundo_menu_inicio w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
                id="nav-content"
                tabIndex="1"
              >
                <ul className="list-reset lg:flex justify-end flex-1 items-center ">
                  <li className="mr-3">
                    <a
                      className="mitexto_color menu_two_mode_black text-poppins-regular inline-block no-underline color-texto-parrafo text-poppins-regular text-poppins-bold-dos rounded-full  hover:text-white hover:bg-green-900  hover:text-black py-2 px-4"
                      href={"/" + site}
                    >
                      Inicio
                    </a>
                  </li>

                  <Item item={menu[0]} key={0} site={site} />
                </ul>
              </div>
            </div>
          </nav>
          {/* ¨PERFIL DE PROVEEDORES */}
          {/* FIN DE PERFIL DE PROVEEDORES */}
          {/* </a> */}
          {/* Final del Menu para pantalllas mas grandes que md, lg  */}
          {site === "proveedores" && (
            <div
              tabIndex="1"
              className="header_icono_ventaja lg:titulo_ajustado_ventaja_banner -mt-24 pl-28"
            >
              <a
                className="text-poppins-regular flex items-center font-bold"
                href={"/" + site}
              >
                <img
                  src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                  className="right-20 alineacion_imagen_inicio"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Logo EPSdelagente"
                  title="Logo EPSdelagente"
                  style={{
                    width: "400px",
                    zIndex: "99999",
                    position: "relative",
                  }}
                />
              </a>
            </div>
          )}
          {site === "proveedores" && (
            <div className="hidden header_icono_ventaja_blanco lg:titulo_ajustado_ventaja_banner -mt-24 pl-28">
              <a
                className="text-poppins-regular flex items-center font-bold"
                href={"/" + site}
              >
                <img
                  src={
                    url_global +
                    "/uploads/logo_epsdelagente_2022_blanco_8c1a4a23da.png"
                  }
                  className="right-20 alineacion_imagen_inicio"
                  alt="Logo EPSdelagente"
                  title="Logo EPSdelagente"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  style={{
                    width: "400px",
                    zIndex: "99999",
                    position: "relative",
                    // left: "0rem",
                  }}
                />
              </a>
            </div>
          )}
          {site === "empresas" && (
            <div
              tabIndex="1"
              className="header_icono_ventaja lg:titulo_ajustado_ventaja_banner -mt-24 pl-8"
            >
              <a
                className="text-poppins-regular flex items-center font-bold"
                href={"/" + site}
              >
                <img
                  src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                  className="right-20 alineacion_imagen_inicio"
                  alt="Logo EPSdelagente"
                  title="Logo EPSdelagente"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  style={{ width: "400px", zIndex: "99999" }}
                />
              </a>
            </div>
          )}
          {site === "empresas" && (
            <div className="hidden header_icono_ventaja_blanco lg:titulo_ajustado_ventaja_banner -mt-24 pl-8">
              <a
                className="text-poppins-regular flex items-center font-bold"
                href={"/" + site}
              >
                <img
                  src={
                    url_global +
                    "/uploads/logo_epsdelagente_2022_blanco_8c1a4a23da.png"
                  }
                  className="right-20 alineacion_imagen_inicio"
                  alt="Logo EPSdelagente"
                  title="Logo EPSdelagente"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  style={{ width: "400px", zIndex: "99999" }}
                />
              </a>
            </div>
          )}
          {site === "personas" && (
            <div
              tabIndex="1"
              className="header_icono_ventaja lg:titulo_ajustado_ventaja_banner -mt-24 pl-8"
            >
              <a
                className="text-poppins-regular flex items-center font-bold"
                href={"/" + site}
              >
                <img
                  src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
                  className="alineacion_imagen_inicio"
                  alt="Logo EPSdelagente"
                  title="Logo EPSdelagente"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  style={{ width: "400px", zIndex: "99999" }}
                />
              </a>
            </div>
          )}
          {site === "personas" && (
            <div className="hidden header_icono_ventaja_blanco lg:titulo_ajustado_ventaja_banner -mt-24 pl-8">
              <a
                className="text-poppins-regular flex items-center font-bold"
                href={"/" + site}
              >
                <img
                  src={
                    url_global +
                    "/uploads/logo_epsdelagente_2022_blanco_8c1a4a23da.png"
                  }
                  className="alineacion_imagen_inicio_blanco"
                  alt="Logo EPSdelagente"
                  title="Logo EPSdelagente"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  style={{ width: "400px", zIndex: "99999" }}
                />
              </a>
            </div>
          )}
          {/* <div>
            <div>
              
            </div>
          </div> */}
          {page !== "politica-cookies-epsdelagente" && (
            <div
              id="aviso-cookies"
              // className="fixed ubicar_banner_cookie aviso-cookies bottom-0 text-center left-0 lg:fixed pb-20 ubicar_banner_cookie w-full bg-footer border-t border-gray-400"
              // className="fixed aviso-cookies bottom-0 text-center left-0 pb-40 xl:pb-20 lg:fixed pb-20 w-full bg-footer border-t border-gray-400"
              className="fixed aviso-cookies pb-4 bottom-0 text-center left-0 w-full bg-footer border-t border-gray-400 activo lg:py-8"
            >
              <div className="flex flex-col space-y-4">
                {/* <div className="flex flex-row"> */}
                <div className="flex flex-col lg:flex-row">
                  {/* <div className="w-8/12 space-y-2 px-12 text-left"> */}
                  <div className="w-full space-y-2 px-12 text-left lg:w-8/12 pt-4">
                    <div className="w-full">
                      <p className="w-full tamano_letra_cookies text-white text-poppins-regular">
                        Para mejorar tu experiencia y recordas tus preferencias
                        en próximas visitas, Utilizamos cookies.
                        <br />
                        <strong className="text-white text-poppins-regular">
                          <a
                            href={
                              url_comfenalco + "politica-cookies-epsdelagente/"
                            }
                            className="underline"
                            target="_blank"
                          >
                            Más información aquí.
                          </a>
                        </strong>
                      </p>
                    </div>

                    <div className="w-full w-full pt-0 lg:pt-4">
                      <p className="w-full tamano_letra_cookies text-white text-poppins-regular">
                        Dar clic en "Editar" para modificar tus preferencias. Al
                        dar clic en Aceptar, aceptas todas las cookies
                      </p>
                    </div>
                  </div>

                  {/* <div class="w-full 2xl:w-4/12"> */}
                  <div className="w-full pt-4 lg:w-4/12 pt-0">
                    <div className="w-full">
                      {/* <div class="flex flex-row space-x-4"> */}
                      <div className="flex flex-row space-x-4 justify-center lg:justify-start">
                        <div className="w-2/5 lg:w-4/12">
                          <button className="w-full buttom_cookies text-white py-2 px-7 rounded-full bg-green-hover font-semibold">
                            Aceptar
                          </button>
                        </div>
                        <div className="w-2/5 lg:w-4/12">
                          <button className="w-full buttom_no_cookies rounded-full text-green-600 bg-white py-2 px-7">
                            No, gracias
                          </button>
                        </div>
                      </div>

                      {/* <div class="w-full mt-4"> */}
                      {/* <div class="w-full flex justify-center 2xl:mt-4 justify-start"> */}
                      <div className="w-full flex justify-center pt-4 lg:justify-start mt-4 pt-0">
                        <div className="w-2/5 lg:w-4/12">
                          <button
                            id="btn-editar-cookies"
                            className="w-full text-white py-2 px-7 bg-gray-400 rounded-full font-semibold"
                          >
                            Editar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="grid grid-rows-2 grid-flow-col gap-4">
              <div className="row-span-2 ...">
                <p className="tamano_letra_cookies text-white text-poppins-regular">
                  Para mejorar tu experiencia y recordas tus preferencias en
                  próximas visitas, Utilizamos cookies.
                </p>
                <strong className="text-white text-poppins-regular">
                  <a
                    href="https://epsdelagente.com.co/politica-cookies-epsdelagente/"
                    className="underline"
                  >
                    Más información aquí.
                  </a>
                </strong>

                <p className="tamano_letra_cookies text-white text-poppins-regular">
                  Dar clic en "Editar" para modificar tus preferencias. Al dar
                  clic en Aceptar, aceptas todas las cookies
                </p>
              </div>
              <div className="col-span-2 ...">
                <button className="w-7/12 buttom_cookies py-2 px-7 rounded-full bg-green-hover font-semibold">
                  Aceptar
                </button>
              </div>
              <div className="col-span-2 ...">
                <button
                  id="btn-editar-cookies"
                  className="w-36 buttom_no_cookies py-2 px-7 bg-gray-400 rounded-full font-semibold"
                >
                  Editar
                </button>
              </div>
            </div>
 */}
              {/* 
            <div className="relative px-3.5 text-center items-center flex flex-wrap 2xl:inline-grid grid-cols-5 gap-4 w-full">
              <div className="relative col-span-4 w-max flex-shrink text-left">
                <p className="tamano_letra_cookies text-white text-poppins-regular">
                  Para mejorar tu experiencia y recordas tus preferencias en
                  próximas visitas, Utilizamos cookies.
                </p>
                <strong className="text-white text-poppins-regular">
                  <a
                    href="https://epsdelagente.com.co/politica-cookies-epsdelagente/"
                    className="underline"
                  >
                    Más información aquí.
                  </a>
                </strong>
              </div>

              <div className="justify-start posicion_aceptacion_index space-x-5 ubicacion_aceptacion_cookies flex md:flex text-base text-white">
                <button className="w-7/12 buttom_cookies py-2 px-7 rounded-full bg-green-hover font-semibold">
                  Aceptar
                </button>

                <button className="w-7/12 buttom_no_cookies py-2 px-7 rounded-full hover:text-green-600 hover:bg-white py-1 px-5">
                  No, gracias
                </button>
              </div>
            </div>
 

            <br></br>
            <br></br>
            <div className="relative px-3.5 text-center items-center flex flex-wrap 2xl:inline-grid grid-cols-5 gap-4 w-full">
              <div className="relative col-span-4 w-max text-left">
                <p className="tamano_letra_cookies text-white text-poppins-regular">
                  Dar clic en "Editar" para modificar tus preferencias. Al dar
                  clic en Aceptar, aceptas todas las cookies
                </p>
              </div>
              <div className="flex justify-start posicion_aceptacion_index space-x-5 ubicacion_aceptacion_cookies md:flex text-base text-white">
                <button
                  id="btn-editar-cookies"
                  class="w-36 buttom_no_cookies py-2 px-7 bg-gray-400 rounded-full font-semibold"
                >
                  Editar
                </button>
              </div>
            </div>
            */}
            </div>
          )}
          {/* APARTADO DE LAS COOKIES MODAL POP UP*/}
          {page !== "politica-cookies-epsdelagente" && (
            // {menu.map(({ node: menus }, i) => {
            // {
            //   // var slug = menus.menu_item[0].map(({ node: slug }, i) => {
            //   //   // console.log("MENU", slug)
            //   // })

            //   console.log("Page",page
            //   )

            //   // console.log("Menus",menus.menu_item[2].sub_item[0].page.slug
            //   // )
            // }

            <div
              style={{
                position: "relative",
                textAlign: "center",
                zIndex: "99999999",
              }}
            >
              <div
                className="hidden hide_modal_close_cookie fixed z-10 overflow-y-scroll top-0 w-full left-0"
                id="modal"
              >
                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                  </div>
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                    &#8203;
                  </span>
                  <div
                    // className="margen_modal_popup inline-block align-center bg-gray-500 rounded-3xl text-left shadow-xl transform transition-all my-8 align-middle xl:w-6/12 lg:w-3/6 md:w-3/6 sm:w-5/6"
                    className="inline-block align-center bg-gray-500 rounded-3xl text-left shadow-xl transform transition-all my-16 align-middle xl:w-7/12 lg:w-7/12 md:w-7/12 sm:w-7/12"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                  >
                    <div
                      style={{ height: "40rem" }}
                      className="scroll-movil overflow-y-scroll custom-overflow rounded-3xl bg-gray-500 relative h-custom-height"
                    >
                      {/* AQUI VA EL CARRUSEL */}
                      <div
                        className="fixed boton_cerrar bottom-4 flex justify-end mr-8"
                        style={{
                          paddingLeft: "89%",
                          bottom: "97%",
                          zIndex: "9",
                        }}
                      >
                        <button
                          tabIndex="1"
                          style={{ zIndex: "99" }}
                          className="hide_modal_cookie relative rotacion_icono_popup p-0 w-12 h-12 bg-white rounded-full hover:bg-gray-50 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                        >
                          <svg
                            viewBox="0 0 20 20"
                            className="w-6 h-6 inline-block"
                          >
                            <path
                              fill="#c5cc0c"
                              d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601 C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399 C15.952,9,16,9.447,16,10z"
                            ></path>
                          </svg>
                        </button>
                      </div>

                      {/* <a
                      style={{ cursor: "pointer" }}
                      href="#"
                      // target="_blank"
                    > */}
                      {/* <img
                        key={index + "slide"}
                        className="w-screen shadow-xl image_popup"
                        src={url_global + slide.Imagen[0].url}
                        alt={`${slide.Titulo}`}
                        title={`${slide.Titulo}`}
                        style={{
                          position: "relative",
                        }}
                        formats={["auto", "webp", "avif"]}
                      /> */}

                      {/* APARTADO PARA EL TITULO, SUB TITULO, DESCRIPCION  */}
                      <div
                        style={{
                          display: "inline-grid",
                          verticalAlign: "middle",
                          // height: "100%",
                        }}
                        // className="absolute inline-grid  contenido_popup w-full text-center top-0 2xl:px-40 py-24 xl:px-24 py-24 lg:px-24 py-12 md:px-40 py-12 sm:px-8 py-12"
                        className="relative contenido_popup w-full text-center top-0 lg:px-40 py-8 xl:px-8 py-8 lg:px-8 py-8 md:px-8 py-8 sm:px-8 py-8"
                      >
                        <p
                          className="text-poppins-extra_bold fuente_titulo text-white"
                          style={{
                            lineHeight: "2rem",
                            fontSize: "1.2em",
                            // color: `${slide.Color_Titulo}`,
                          }}
                        >
                          Permisos de accesos para las cookies
                        </p>
                        {/* <p
                          className="mt-8 text-poppins-bold titulo_subtitulo_popup"
                          style={{
                            color: `${slide.Color_subtitulo}`,
                          }}
                        >
                          {slide.Sub_titulo}
                        </p> */}{" "}
                        {/* <hr className="my-2 separador_divisora_popup" /> */}
                        <p
                          // style={{
                          //   color: `${slide.Color_descripcion}`,
                          // }}
                          className="text-sm text-white text-poppins-regular mt-2 text-slate-500 text-left titulo_descripcion_popup"
                        >
                          Este sitio web utiliza cookies para mejorar su
                          experiencia mientras navega por el sitio web. Estas se
                          clasifican según su finalidad y se almacenan en su
                          navegador, ya que son esenciales para el
                          funcionamiento de funcionalidades básicas. Consulta
                          nuestra política &nbsp;
                          <a
                            style={{ cursor: "pointer" }}
                            href={
                              url_comfenalco + "politica-cookies-epsdelagente/"
                            }
                            target="_blank"
                            className="hide_modal_aqui mt-1 text-sm leading-tight font-medium underline"
                          >
                            Aquí
                          </a>
                        </p>
                      </div>

                      <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                        {/* <div className="relative flex inline-flex justify-start"> */}

                        {/* <div className="relative text-poppins-regular text-black flex">
                          <span>Necesarias</span>
                        </div> */}
                        {/* <span>Necesarias</span> */}
                        {/* </div> */}
                        {/* <div className="relative text-poppins-regular text-black flex">
                        <span>Necesarias</span>
                      </div> */}
                        <div
                          id="toogle-necesario"
                          // style={{
                          //   // display: "inline-grid",
                          //   // display: "flex",
                          //   // verticalAlign: "middle",
                          //   alignItems: "center",
                          //   // height: "100%",
                          // }}
                          // className="flex flex-col relative contenido_popup w-full text-center top-0 2xl:px-40 xl:px-24 lg:px-24 md:px-40 sm:px-8"
                          // className="btn-link-cookie gap-2 flex flex-cols-1 justify-end relative w-full text-center top-0 2xl:px-40 xl:px-24 lg:px-24 md:px-40 sm:px-8"
                          className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0"
                        >
                          <div className="grid grid-cols-1">
                            <div
                              style={
                                {
                                  // position: "relative",
                                  // top: "1rem !important",
                                }
                              }
                              className="relative col-span-1 swiper-button-next"
                              tabindex="0"
                              role="button"
                              aria-label="Next slide"
                              aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                              aria-disabled="false"
                            ></div>
                          </div>
                          <div className="grid grid-cols-2 w-full gap-1">
                            {/* <div className="relative text-poppins-regular text-white flex bottom-8 left-8"> */}
                            {/* <div className="relative right-20 text-poppins-regular text-black flex md:right-1/3 lg:right-32 xl:right-1/3 2xl:right-3/4"> */}
                            <div className="relative text-poppins-regular text-black flex">
                              <span>Necesarias</span>
                            </div>
                            {/* <div className="text-white relative flex bottom-8 left-8 justify-end"> */}
                            <div className="text-white relative flex justify-end lg:justify-star">
                              {/* <span> */}
                              {/* <div className="relative flex bottom-8 left-8 theme-switch-wrapper"> */}
                              <div className="relative flex theme-switch-wrapper">
                                <label
                                  className="theme-switch"
                                  htmlFor="chkNecesarias"
                                >
                                  <input
                                    type="checkbox"
                                    id="chkNecesarias"
                                    tabIndex="1"
                                  />
                                  <div
                                    className="slider-cookie-consent round-cookie"
                                    tabIndex="1"
                                    id="sliderChkNecesarias"
                                  ></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="info_necesarias"
                        className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                      >
                        <p className="text-left text-black">
                          Estas cookies son esenciales para que el sitio web
                          funcione correctamente. Permiten la navegación y el
                          uso de funciones básicas, como el acceso a áreas
                          seguras del sitio. Sin ellas, el sitio no podría
                          funcionar.
                        </p>
                      </div>

                      <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                        <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                          <div className="grid grid-cols-1">
                            <div
                              id="toogle-analisis"
                              className="relative col-span-1 swiper-button-next"
                              tabindex="0"
                              role="button"
                              aria-label="Next slide"
                              aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                              aria-disabled="false"
                            ></div>
                          </div>
                          <div className="grid grid-cols-2 w-full gap-1">
                            <div className="relative text-poppins-regular text-black flex">
                              <span>Analisis</span>
                            </div>

                            <div className="text-white relative flex justify-end lg:justify-star">
                              <div className="relative flex theme-switch-wrapper">
                                <label
                                  className="theme-switch"
                                  htmlFor="chkAnalisis"
                                >
                                  <input
                                    type="checkbox"
                                    id="chkAnalisis"
                                    tabIndex="1"
                                  />
                                  <div
                                    className="slider-cookie-consent round-cookie"
                                    tabIndex="1"
                                    id="sliderChkAnalisis"
                                  ></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="info_analisis"
                        className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                      >
                        <p className="text-left text-black">
                          Estas cookies nos ayudan a entender cómo los usuarios
                          interactúan con nuestro sitio. Recopilan información
                          sobre las páginas visitadas, el tiempo que se pasa en
                          cada una y posibles errores. Esta información es
                          anónima
                        </p>
                      </div>

                      <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                        <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                          <div className="grid grid-cols-1">
                            <div
                              id="toogle-publicidad"
                              className="relative col-span-1 swiper-button-next"
                              tabindex="0"
                              role="button"
                              aria-label="Next slide"
                              aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                              aria-disabled="false"
                            ></div>
                          </div>
                          <div className="grid grid-cols-2 w-full gap-1">
                            <div className="relative text-poppins-regular text-black flex">
                              <span>Publicidad</span>
                            </div>

                            <div className="text-white relative flex justify-end lg:justify-star">
                              <div className="relative flex theme-switch-wrapper">
                                <label
                                  className="theme-switch"
                                  htmlFor="chkPublicidad"
                                >
                                  <input
                                    type="checkbox"
                                    id="chkPublicidad"
                                    tabIndex="1"
                                  />
                                  <div
                                    className="slider-cookie-consent round-cookie"
                                    tabIndex="1"
                                    id="sliderChkPublicidad"
                                  ></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="info_publicidad"
                        className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                      >
                        <p className="text-left text-black">
                          Estas cookies se utilizan para mostrar anuncios
                          relevantes para los usuarios. Pueden recopilar
                          información sobre hábitos de navegación y
                          preferencias, permitiendo personalizar la publicidad
                          que ves en nuestro sitio y otros lugares.
                        </p>
                      </div>

                      <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                        <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                          <div className="grid grid-cols-1">
                            <div
                              id="toogle-funcionalidad"
                              className="relative col-span-1 swiper-button-next"
                              tabindex="0"
                              role="button"
                              aria-label="Next slide"
                              aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                              aria-disabled="false"
                            ></div>
                          </div>
                          <div className="grid grid-cols-2 w-full gap-1">
                            <div className="relative text-poppins-regular text-black flex">
                              <span>Funcionalidades</span>
                            </div>

                            <div className="text-white relative flex justify-end lg:justify-star">
                              <div className="relative flex theme-switch-wrapper">
                                <label
                                  className="theme-switch"
                                  htmlFor="chkFuncionalidad"
                                >
                                  <input
                                    type="checkbox"
                                    id="chkFuncionalidad"
                                    tabIndex="1"
                                  />
                                  <div
                                    className="slider-cookie-consent round-cookie"
                                    tabIndex="1"
                                    id="sliderChkFuncionalidad"
                                  ></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="info_funcionalidad"
                        className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                      >
                        <p className="text-left text-black">
                          Estas cookies permiten recordar las elecciones que
                          haces para ofrecerte una experiencia más
                          personalizada. También pueden utilizarse para
                          proporcionar características mejoradas y
                          personalizadas.
                        </p>
                      </div>

                      <div className="relative flex w-11/12 text-center mx-auto md:w-7/12">
                        <div className="h-full items-center btn-link-cookie gap-2 flex flex-cols-1 relative w-full text-center top-0">
                          <div className="grid grid-cols-1">
                            <div
                              id="toogle-otros"
                              className="relative col-span-1 swiper-button-next"
                              tabindex="0"
                              role="button"
                              aria-label="Next slide"
                              aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                              aria-disabled="false"
                            ></div>
                          </div>
                          <div className="grid grid-cols-2 w-full gap-1">
                            <div className="relative text-poppins-regular text-black flex">
                              <span>Otros</span>
                            </div>

                            <div className="text-white relative flex justify-end lg:justify-star">
                              <div className="relative flex theme-switch-wrapper">
                                <label
                                  className="theme-switch"
                                  htmlFor="chkOtros"
                                >
                                  <input
                                    type="checkbox"
                                    id="chkOtros"
                                    tabIndex="1"
                                  />
                                  <div
                                    className="slider-cookie-consent round-cookie"
                                    tabIndex="1"
                                    id="sliderChkOtros"
                                  ></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="info_otros"
                        className="hidden relative btn-link-cookie flex w-11/12 text-center mx-auto md:w-7/12"
                      >
                        <p className="text-left text-black">
                          Esta categoría incluye cookies que no encajan en las
                          anteriores, como las que se utilizan para la
                          integración de redes sociales o para medir el
                          rendimiento del sitio. Estas cookies pueden ser
                          gestionadas por terceros.
                        </p>
                      </div>

                      {/* </div> */}
                      {/* 
                    <div
                      style={{
                        display: "flex",

                        alignItems: "center",
                        height: "100%",
                      }}
                      className="mt-12 gap-2 flex flex-cols-3 relative w-full text-center top-0 2xl:px-40 xl:px-24 lg:px-24 md:px-40 sm:px-8"
                    >
                      <div
                        style={{ position: "relative" }}
                        className="pl-8 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>

                      <div className="relative text-poppins-regular text-black flex">
                        <span>Analisis</span>
                      </div>

                      <div className="text-white relative flex justify-end">
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkAccesibilidad"
                          >
                            <input
                              type="checkbox"
                              id="chkAccesibilidad"
                              tabIndex="1"
                            />
                            <div
                              className="slider round"
                              tabIndex="1"
                              id="sliderChkAccesibilidad"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div> */}

                      {/* <div
                      style={{
                     
                        display: "flex",
                      
                        alignItems: "center",
                        height: "100%",
                      }}
                      
                      className="mt-12 gap-2 flex flex-cols-3 relative w-full text-center top-0 2xl:px-40 xl:px-24 lg:px-24 md:px-40 sm:px-8"
                    >
                      <div
                        style={{ position: "relative" }}
                        className="pl-8 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                      
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Publicidad</span>
                      </div>
                      
                      <div className="text-white relative flex justify-end">
                        
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkAccesibilidad"
                          >
                            <input
                              type="checkbox"
                              id="chkAccesibilidad"
                              tabIndex="1"
                            />
                            <div
                              className="slider round"
                              tabIndex="1"
                              id="sliderChkAccesibilidad"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div> */}

                      {/* <div
                      style={{
                        
                        display: "flex",
                  
                        alignItems: "center",
                        height: "100%",
                      }}
                      
                      className="mt-12 gap-2 flex flex-cols-3 relative w-full text-center top-0 2xl:px-40 xl:px-24 lg:px-24 md:px-40 sm:px-8"
                    >
                      <div
                        style={{ position: "relative" }}
                        className="pl-8 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                      
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Funcionalidades</span>
                      </div>
                      
                      <div className="text-white relative flex justify-end">
                        
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkAccesibilidad"
                          >
                            <input
                              type="checkbox"
                              id="chkAccesibilidad"
                              tabIndex="1"
                            />
                            <div
                              className="slider round"
                              tabIndex="1"
                              id="sliderChkAccesibilidad"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div> */}

                      {/* <div
                      style={{
                        
                        display: "flex",
                        
                        alignItems: "center",
                        height: "100%",
                      }}
                      
                      className="mt-12 gap-2 flex flex-cols-3 relative w-full text-center top-0 2xl:px-40 xl:px-24 lg:px-24 md:px-40 sm:px-8"
                    >
                      <div
                        style={{ position: "relative" }}
                        className="pl-8 swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      ></div>
                      
                      <div className="relative text-poppins-regular text-black flex">
                        <span>Otros</span>
                      </div>
                      
                      <div className="text-white relative flex justify-end">
                      
                        <div className="relative flex theme-switch-wrapper">
                          <label
                            className="theme-switch"
                            htmlFor="chkAccesibilidad"
                          >
                            <input
                              type="checkbox"
                              id="chkAccesibilidad"
                              tabIndex="1"
                            />
                            <div
                              className="slider round"
                              tabIndex="1"
                              id="sliderChkAccesibilidad"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div> */}

                      {/* <div
                        style={{ position: "relative" }}
                        class="swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      >
                     
                      </div>
                      <div className="relative text-poppins-regular text-white flex bottom-8 left-8">
                        <span>Analisis</span>
                      </div>
                      <div className="text-white relative flex bottom-8 left-8 justify-end">
                      
                        <span>
                          <div className="relative flex bottom-8 left-8 theme-switch-wrapper">
                            <label
                              className="theme-switch"
                              htmlFor="chkAccesibilidad"
                            >
                              <input
                                type="checkbox"
                                id="chkAccesibilidad"
                                tabIndex="1"
                              />
                              <div
                                className="slider round"
                                tabIndex="1"
                                id="sliderChkAccesibilidad"
                              ></div>
                            </label>
                          </div>
                        </span>
                      </div> */}

                      {/* <div
                        style={{ position: "relative" }}
                        class="swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      >
                       
                      </div>
                      <div className="relative text-poppins-regular text-white flex bottom-8 left-8">
                        <span>Publicidad</span>
                      </div>
                      <div className="text-white relative flex bottom-8 left-8 justify-end">
                    
                        <span>
                          <div className="relative flex bottom-8 left-8 theme-switch-wrapper">
                            <label
                              className="theme-switch"
                              htmlFor="chkAccesibilidad"
                            >
                              <input
                                type="checkbox"
                                // id="chkAccesibilidad"
                                tabIndex="1"
                              />
                              <div
                                className="slider round"
                                tabIndex="1"
                                // id="sliderChkAccesibilidad"
                              ></div>
                            </label>
                          </div>
                        </span>
                      </div> */}

                      {/* <div
                        style={{ position: "relative" }}
                        class="swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      >
                      
                      </div>
                      <div className="relative text-poppins-regular text-white flex bottom-8 left-8">
                        <span>Funcionalidades</span>
                      </div>
                      <div className="text-white relative flex bottom-8 left-8 justify-end">
                        
                        <span>
                          <div className="relative flex bottom-8 left-8 theme-switch-wrapper">
                            <label
                              className="theme-switch"
                              htmlFor="chkAccesibilidad"
                            >
                              <input
                                type="checkbox"
                                // id="chkAccesibilidad"
                                tabIndex="1"
                              />
                              <div
                                className="slider round"
                                tabIndex="1"
                                // id="sliderChkAccesibilidad"
                              ></div>
                            </label>
                          </div>
                        </span>
                      </div> */}

                      {/* <div
                        style={{ position: "relative" }}
                        class="swiper-button-next"
                        tabindex="0"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-6d53a2beb5cd4ee8"
                        aria-disabled="false"
                      >
                     
                      </div>
                      <div className="relative text-poppins-regular text-white flex bottom-8 left-8">
                        <span>Otros</span>
                      </div>
                      <div className="text-white relative flex bottom-8 left-8 justify-end">
                        
                        <span>
                          <div className="relative flex bottom-8 left-8 theme-switch-wrapper">
                            <label
                              className="theme-switch"
                              htmlFor="chkAccesibilidad"
                            >
                              <input
                                type="checkbox"
                                // id="chkAccesibilidad"
                                tabIndex="1"
                              />
                              <div
                                className="slider round"
                                tabIndex="1"
                                // id="sliderChkAccesibilidad"
                              ></div>
                            </label>
                          </div>
                        </span>
                      </div> */}
                      {/* </div> */}

                      {/* FIN DEL CARRUSEL */}
                      <div className="relative pt-8 px-4 pb-4 text-right rounded-3xl">
                        {/* <a
                      style={{ cursor: "pointer" }}
                      className="hide_modal block mt-1 text-lg leading-tight font-medium green-secundary hover:underline"
                    >
                      Cerrar
                    </a> */}
                        <button
                          // style={{ width: "30%", position: "relative" }}
                          // style="width: 30%;position: relative;"
                          className="relative guardar_cookie mitexto_color w-full text-poppins-bold btn-link hover:bg-green-800 md:w-2/5"
                          // onclick="window.open('https://ecd-institucional-recursos.s3.us-east-2.amazonaws.com/Portabilidad/Ver-decreto-1683-de-2013+(1).pdf','_blank')"
                        >
                          Guardar y aceptar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* // })} */}
          {/* FINAL APARTADO DE LAS COOKIES MODAL POP UP */}
        </header>
      </div>
    </>
  )
}

function Item(payload) {
  if (!payload || !payload.item.node || !payload.item.node.menu_item)
    return <></>
  const listItems = payload.item.node?.menu_item
    .filter(f => f.site === payload.site)
    .map((node, i) => {
      if (
        node.site != undefined &&
        node?.page?.slug != null &&
        node?.page?.slug != "blogs"
      ) {
        return (
          <li
            tabIndex="1"
            className="relative mitexto estilo_menu_principal parent text-poppins-bold-dos"
            key={`n-${i}`}
          >
            <a
              // href={"/" + node?.page?.slug}
              href={"/" + node.site + "/" + node?.page?.slug}
              className="flex mitexto_color menu_two_mode_black sin_espacios justify-between color-texto-parrafo text-poppins-regular text-poppins-bold-dos md:inline-flex py-2 px-4 items-center rounded-full  hover:text-white hover:bg-green-900  space-x-2"
            >
              <span className="text-poppins-bold-dos">{node.title}</span>
            </a>
            <SubItem node={node} />
          </li>
        )
      }

      if (node?.page?.site == undefined && node?.page?.slug == "blogs") {
        return (
          <li
            tabIndex="1"
            className="relative mitexto estilo_menu_principal parent text-poppins-bold-dos"
            key={`n-${i}`}
          >
            <a
              href={"/" + node?.page?.slug}
              className="flex mitexto_color menu_two_mode_black sin_espacios justify-between color-texto-parrafo text-poppins-regular text-poppins-bold-dos md:inline-flex py-2 px-4 items-center rounded-full  hover:text-white hover:bg-green-900  space-x-2"
            >
              <span className="text-poppins-bold-dos">{node.title}</span>
            </a>
            <SubItem node={node} />
          </li>
        )
      }
      if (node.site != undefined || node?.page?.slug === null) {
        return (
          <li
            tabIndex="1"
            className="relative mitexto menu_principal estilo_menu_principal parent text-poppins-bold-dos color-texto-parrafo"
            key={`n-${i}`}
          >
            <a
              href={"#"}
              className="flex mitexto_color menu_two_mode_black sin_espacios justify-between color-texto-parrafo text-poppins-regular text-poppins-bold-dos md:inline-flex py-2 px-4 items-center rounded-full hover:text-white hover:bg-green-900  space-x-2"
            >
              <svg
                className="icono fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                style={{ color: "gray", display: "block" }}
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>{" "}
              </svg>
              <span className="text-poppins-bold-dos">{node.title}</span>
            </a>
            <SubItem node={node} />
          </li>
        )
      }
    })

  return listItems
}

function SubItem(payload) {
  if (!payload.node || !payload.node.sub_item) return <></>

  const listItems = payload.node?.sub_item.map((submenu, i) => (
    <li tabIndex="1" className="mr-3" key={`s-${i}`}>
      <a
        className="text-poppins-regular flex px-4 py-2 hover:bg-gray-200"
        href={"/" + submenu?.page?.site + "/" + submenu?.page?.slug}
      >
        {submenu?.title}
      </a>
    </li>
  ))

  return (
    <ul
      tabIndex="1"
      className="w-full sub_items child hidden transition duration-300 md:absolute top-full right-0 md:w-80 bg-white md:shadow-lg md:rounded-b z-30"
    >
      {listItems}
    </ul>
  )
}

/////////////////////////////////////////////////////////////////// PARA MOVILES DESPLEGABLE /////////////////////////////////////////////////////////////////////////////////////////

function Item_dos(payload) {
  if (!payload || !payload.item.node || !payload.item.node.menu_item)
    return <></>

  const listItems = payload.item.node?.menu_item
    .filter(f => f.site === payload.site)
    .map((node, i) => {
      if (node.site != undefined && node?.page?.slug != null) {
        return (
          <li
            className="relative mitexto estilo_menu_principal parent text-poppins-bold-dos"
            key={`n-${i}`}
          >
            <a
              // href={"/" + node.site + "/" + node?.page?.slug}
              href={"/" + node?.page?.slug}
              className="flex mitexto mitexto_color sin_espacios_dos justify-start color-texto-parrafo text-poppins-regular text-poppins-bold-dos md:inline-flex py-2 px-4 items-center rounded-full  hover:text-white hover:bg-green-900  space-x-2"
            >
              <span className="text-poppins-bold-dos">{node.title}</span>
            </a>
            <SubItem_dos node={node} />
          </li>
        )
      }
      if (node.site != undefined || node?.page?.slug === null) {
        return (
          <li
            className="relative mitexto menu_principal estilo_menu_principal parent text-poppins-bold-dos color-texto-parrafo"
            key={`n-${i}`}
          >
            <a
              href={"#"}
              className="flex mitexto mitexto_color sin_espacios_dos justify-start color-texto-parrafo text-poppins-regular text-poppins-bold-dos md:inline-flex py-2 px-4 items-center rounded-full hover:text-white hover:bg-green-900  space-x-2"
            >
              <svg
                className="icono fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                style={{ color: "gray", display: "block" }}
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>{" "}
              </svg>
              <span className="text-poppins-bold-dos">{node.title}</span>
            </a>
            <SubItem_dos node={node} />
          </li>
        )
      }
    })

  return listItems
}

function SubItem_dos(payload) {
  if (!payload.node || !payload.node.sub_item) return <></>

  const listItems = payload.node?.sub_item.map((submenu, i) => (
    <li className="mr-3 mitexto_color" key={`s-${i}`}>
      <a
        className="text-poppins-regular flex px-4 py-2 hover:bg-gray-200"
        href={"/" + submenu?.page?.site + "/" + submenu?.page?.slug}
      >
        {submenu?.title}
      </a>
    </li>
  ))

  return (
    <ul className="mitexto w-full sub_items child hidden transition duration-300 md:absolute top-full right-0 md:w-80 bg-white md:shadow-lg md:rounded-b z-30">
      {listItems}
    </ul>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
